<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="table"
          title="공지사항 목록"
          tableId="table"
          :columnSetting="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :data="grid.data"
          @rowClick="rowClick"
          :isExcelDown="false"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getNoticeList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <c-card title="상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="신규" icon="add" @btnClicked="addNotice" />
                <c-btn
                  v-if="editable && deleteable"
                  :url="deleteUrl"
                  :isSubmit="true"
                  :param="data"
                  mappingType="DELETE"
                  label="삭제"
                  icon="remove"
                  @beforeAction="deleteNotice"
                  @btnCallback="deleteCallback" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveNotice"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-8">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="공지사항 제목"
                  name="noticeTitle"
                  v-model="data.noticeTitle">
                </c-text>
              </div>
              <div class="col-4">
                <c-select
                  :editable="editable && dataeditable"
                  codeGroupCd="NOTICE_CLASS_CD"
                  type="edit"
                  :required="true"
                  itemText="codeName"
                  itemValue="code"
                  name="grade"
                  label="공지사항분류"
                  v-model="data.noticeClassCd"
                ></c-select>
              </div>
              <div class="col-4" v-show="editable">
                <c-radio
                  :editable="editable && dataeditable"
                  :comboItems="popupFlagItems"
                  label="팝업여부"
                  name="popupFlag"
                  v-model="data.popupFlag">
                </c-radio>
              </div>
              <div class="col-4" v-show="editable && data.popupFlag == 'Y'">
                <c-datepicker
                  :editable="editable && dataeditable"
                  label="팝업 시작일"
                  :required="data.popupFlag == 'Y'"
                  name="popupStartDate"
                  v-model="data.popupStartDate"
                  :end="data.popupEndDate"
                />
              </div>
              <div class="col-4" v-show="editable && data.popupFlag == 'Y'">
                <c-datepicker
                  :editable="editable && dataeditable"
                  label="팝업 종료일"
                  :required="data.popupFlag == 'Y'"
                  name="popupEndDate"
                  v-model="data.popupEndDate"
                  :start="data.popupStartDate"
                />
              </div>
              <div class="col-12">
                <c-textarea
                  :editable="editable && dataeditable"
                  type="editor"
                  label="내용"
                  name="noticeContents"
                  :editheight="30"
                  v-model="data.noticeContents">
                </c-textarea>
              </div>
            </template>
          </c-card>
          <c-card title="첨부파일" class="cardClassDetailForm" style="margin-top:10px">
            <template slot="card-detail">
              <div class="col-12" style="margin-top:10px">
                <c-upload
                  :attachInfo="attachInfo"
                  :editable="editable && dataeditable"
                  label="첨부파일">
                </c-upload>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'notice-partner-manage',
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'NOTICE_VENDOR',
        taskKey: '',
      },
      searchParam: {
        vendorFlag: 'Y',
        useFlag: 'Y',
      },
      popupFlagItems: [
        { code: 'Y', codeName: '팝업사용' },
        { code: 'N', codeName: '미사용' },
      ],
      grid: {
        columns: [
          {
            name: 'noticeClassName',
            field: 'noticeClassName',
            label: '공지분류',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
          },
          {
            name: 'noticeTitle',
            field: 'noticeTitle',
            label: '제목',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            label: '등록일시',
            align: 'center',
            style: 'width: 180px',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        sysNoticeId: '',  // 공지사항 번호
        noticeClassCd: null,  // 공지분류
        noticeClassName: '',  // 공지분류명
        noticeTitle: '',  // 제목
        noticeContents: '',  // 내용
        popupFlag: 'N',  // 팝업여부
        popupStartDate: '',  // 팝업시작일자
        popupEndDate: '',  // 팝업종료일자
        vendorFlag: 'Y',
      },
      listUrl: '',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      saveUrl: transactionConfig.sys.notice.insert.url,
      deleteUrl: '',
      editable: true,
      saveable: false,
      deleteable: false,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.sys.notice.list.url;
      this.detailUrl = selectConfig.sys.notice.get.url;
      this.insertUrl = transactionConfig.sys.notice.insert.url;
      this.updateUrl = transactionConfig.sys.notice.update.url;
      this.deleteUrl = transactionConfig.sys.notice.delete.url;
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getNoticeList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.updateMode = true;
      this.$http.url = this.$format(this.detailUrl, row.sysNoticeId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.$set(this.attachInfo, 'taskKey', row.sysNoticeId)
        this.dataeditable = true;
      },
      () => {
      });
    },
    addNotice() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        sysNoticeId: '',  // 공지사항 번호
        noticeClassCd: null,  // 공지분류
        noticeClassName: '',  // 공지분류명
        noticeTitle: '',  // 제목
        noticeContents: '',  // 내용
        popupFlag: 'N',  // 팝업여부
        popupStartDate: '',  // 팝업시작일자
        popupEndDate: '',  // 팝업종료일자
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        sysNoticeId: '',  // 공지사항 번호
        noticeClassCd: null,  // 공지분류
        noticeClassName: '',  // 공지분류명
        noticeTitle: '',  // 제목
        noticeContents: '',  // 내용
        popupFlag: 'N',  // 팝업여부
        popupStartDate: '',  // 팝업시작일자
        popupEndDate: '',  // 팝업종료일자
      };
    },
    saveNotice() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.data.vendorFlag = 'Y'
              console.log(this.data)
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteNotice() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.sysNoticeId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getList();
        this.rowClick({ sysNoticeId: result.data.sysNoticeId })
      } else {
        this.getNoticeList();
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getNoticeList();
    },
  }
};
</script>
